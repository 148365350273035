import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Markdown from "../components/Markdown";
import Section from "../components/Section";

import CallToAction from "../components/CallToAction";
import Layout from "../components/Layout";
import PostHero from "../components/Hero/PostHero";
import SEO from "../components/SEO";
import Ticker from "../components/Ticker";

import SecondaryButton from "../components/Button/SecondaryButton";


const PostTemplate = ({
  data
}) => {
  const {
    mdx: {
      body,
      frontmatter: {
        title,
        postDate,
        author,
        postImage
      }
    }
  } = data;

  return (
    <Layout>
      <SEO title={ title } />

      <PostHero
        postTitle={ title }
        postDate={ postDate }
        postAuthor={ author }
        postImage={ getImage(postImage) }
      />

      <Markdown
        MDXRendererComponent={
          <MDXRenderer>{ body }</MDXRenderer>
        }
      />

      <Section
        sectionTitle="Interested In Working With Us?"
        sectionDescription="Schedule your marketing consultation today."
        textAlignment="center"
        darkBackground
        Component={
          <CallToAction
            darkBackground
            ButtonComponent={
              <SecondaryButton
                linkLocation="/contact"
                linkText="Start A Project"
                darkBackground
              />
            }
          />
        }
      />

      <Section
        Component={
          <Ticker
            template="marketing-partner"
            itemWidth={ 16 }
          />
        }
      />
    </Layout>
  )
}


export const pageQuery = graphql`
  query($id: String) {
    mdx(id: {
      eq: $id
    }) {
      id
      body
      frontmatter {
        title
        postDate(formatString: "MMMM DD, YYYY")
        author
        postImage {
          childImageSharp {
            gatsbyImageData(
              width: 750
              quality: 60
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;


export default PostTemplate;
