import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
} from "../../theme/style-constants";


export const StyledSection = styled.section`
  width: 100%;
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.post };
  width: 90%;
  margin: 0 auto;
  padding: 0 0 5rem;

  h2 {
    margin: 2rem 0 1rem;
    font-size: 2rem;
    font-weight: 300;
  }

  h3 {
    margin: 2rem 0 1rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  h4 {
    margin: 2rem 0 1rem;
    font-size: 1.2rem;
    font-weight: 300;
  }

  h5 {
    margin: 2rem 0 1rem;
    font-size: 1.15rem;
    font-weight: 300;
  }

  h6 {
    margin: 2rem 0 1rem;
    font-size: 1.1rem;
    font-weight: 300;
  }

  p {
    margin: 1rem 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
  }

  li {
    margin: 0.5rem 0;
    font-size: 18px;
    font-weight: 300;
  }

  a {
    color: ${ COLORS.text.orange };
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${ COLORS.text.orange }99;
    }

    &:visited {
      color: ${ COLORS.text.orange };
    }
  }

  blockquote {
    margin: 3rem;
    color: ${ COLORS.text.black }AA;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-weight: 300;
    color: ${ COLORS.text.dark };
  }
`;
