import * as React from "react";

import {
  Container,
  DetailsContainer,
  StyledAuthor,
  StyledDate,
  StyledHeading,
  StyledImage,
  StyledSection,
  StyledSeparator
} from "./style";


const PostHero = ({
  postTitle,
  postDate,
  postImage,
  postAuthor
}) => {
  return (
    <StyledSection>
      <Container>
        <StyledHeading>
          { postTitle }
        </StyledHeading>
        <DetailsContainer>
          <StyledDate>
            { postDate }
          </StyledDate>
          <StyledSeparator>
            &middot;
          </StyledSeparator>
          <StyledAuthor>
            { postAuthor }
          </StyledAuthor>
        </DetailsContainer>
        <StyledImage
          image={ postImage }
        />
      </Container>
    </StyledSection>
  )
}


export default PostHero;
