import * as React from "react";

import {
  Container,
  StyledSection
} from "./style";


const Markdown = ({
  MDXRendererComponent
}) => {
  return (
    <StyledSection>
      <Container>
        { MDXRendererComponent }
      </Container>
    </StyledSection>
  )
}


export default Markdown;
